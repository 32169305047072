import Swiper, { Navigation } from 'swiper';
const goalsSlider = () => {
    if (!matchMedia('(max-width: 1279px)').matches)
        return;
    const section = document.querySelector('.goals-section');
    if (!section)
        return;
    const slider = section.querySelector('.swiper');
    if (!slider)
        return;
    new Swiper(slider, {
        modules: [Navigation],
        speed: 400,
        autoHeight: true,
        navigation: {
            prevEl: section.querySelector('.prev'),
            nextEl: section.querySelector('.next')
        },
        breakpoints: {
            576: {
                slidesPerView: 2
            }
        }
    });
};
goalsSlider();
