var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
class Map {
    constructor({ container }) {
        if (!container)
            throw new Error("Контейнер не определён");
        this.container = container;
    }
    static loadAPI(key) {
        return new Promise((resolve) => {
            if (Map.ymapIsReady)
                resolve('loaded');
            const script = document.createElement('script');
            script.src = `https://api-maps.yandex.ru/3.0/?apikey=${key}&lang=ru_RU`;
            script.addEventListener('load', () => __awaiter(this, void 0, void 0, function* () {
                yield ymaps3.ready;
                Map.ymapIsReady = true;
                resolve("loaded");
            }));
            document.body.append(script);
        });
    }
    createMap(center = [37.617635, 55.755814], zoom = 15) {
        this.ymap = new ymaps3.YMap(this.container, {
            location: {
                center, zoom
            }
        }, [
            new ymaps3.YMapDefaultSchemeLayer({}),
            new ymaps3.YMapDefaultFeaturesLayer({ zIndex: 1800 })
        ]);
    }
    createDefaultMarker() {
        const marker = document.createElement('div');
        marker.className = 'default-map-marker';
        return marker;
    }
    addMarker(coordinates, content) {
        if (!this.ymap)
            return;
        const marker = new ymaps3.YMapMarker({
            coordinates,
            draggable: false,
        }, content);
        this.ymap.addChild(marker);
    }
}
Map.ymapIsReady = false;
export default Map;
