import Swiper, { Autoplay } from 'swiper';
const projectsSliders = () => {
    const sliders = document.querySelector('.projects-page .sliders');
    if (!sliders)
        return;
    const slider1 = sliders.querySelector('.slider1 .swiper');
    const slider2 = sliders.querySelector('.slider2 .swiper');
    const init = (container, reverse = false) => {
        const isMobile = matchMedia('(max-width: 1279px)').matches;
        return new Swiper(container, {
            modules: [Autoplay],
            slidesPerView: 'auto',
            direction: isMobile ? 'horizontal' : 'vertical',
            speed: 4500,
            loop: true,
            autoplay: {
                delay: 0,
                disableOnInteraction: false,
                reverseDirection: reverse
            }
        });
    };
    if (slider1)
        init(slider1);
    if (slider2)
        init(slider2, true);
};
projectsSliders();
