import { ymapKey } from '../const';
import Swiper from 'swiper';
import Map from '../libs/Map';
import { startWatch } from '../utils/startWatch';
const contactsMap = () => {
    const mapContainer = document.querySelector('#contacts-map');
    if (!mapContainer)
        return false;
    const init = () => {
        if (!mapContainer.dataset.coords)
            return;
        const points = JSON.parse(mapContainer.dataset.coords);
        const coords = points.map(point => {
            const split = point.split(',');
            return [parseFloat(split[1]), parseFloat(split[0])];
        });
        const map = new Map({
            container: mapContainer
        });
        map.createMap(coords[0]);
        coords.forEach(coordinates => map.addMarker(coordinates, map.createDefaultMarker()));
    };
    startWatch({
        handler(entry) {
            if (!entry.isIntersecting)
                return;
            const result = Map.loadAPI(ymapKey);
            result.then(init);
        },
        elements: [mapContainer]
    });
};
contactsMap();
const contactsNav = () => {
    const navContainer = document.querySelector('.contacts-nav .swiper');
    if (!navContainer)
        return;
    new Swiper(navContainer, {
        speed: 300,
        slidesPerView: 'auto',
    });
};
contactsNav();
