import Burger from "../libs/Burger";
const header = () => {
    const burger = document.querySelector('#header-burger');
    if (!burger)
        return;
    const mobileMenu = document.querySelector('#mobile-menu');
    if (!mobileMenu)
        return;
    const search = document.querySelector('#header-search');
    const headerBurger = new Burger(burger);
    headerBurger.addAction(() => {
        mobileMenu.classList.toggle('opened');
    });
};
header();
