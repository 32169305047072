import Swiper, { Navigation, Thumbs } from 'swiper';
const contentSlider = () => {
    const section = document.querySelector('.content-slider');
    const mainSwiper = section === null || section === void 0 ? void 0 : section.querySelector('.main-swiper');
    const thumbsSwiper = section === null || section === void 0 ? void 0 : section.querySelector('.thumbs-swiper');
    if (!section || !mainSwiper || !thumbsSwiper)
        return;
    const thumbs = new Swiper(thumbsSwiper, {
        modules: [Navigation],
        speed: 400,
        slidesPerView: 2,
        breakpoints: {
            768: {
                slidesPerView: 3
            }
        }
    });
    new Swiper(mainSwiper, {
        modules: [Thumbs, Navigation],
        speed: 700,
        spaceBetween: 30,
        thumbs: {
            swiper: thumbs
        },
        navigation: {
            prevEl: section.querySelector('.prev'),
            nextEl: section.querySelector('.next')
        }
    });
};
contentSlider();
