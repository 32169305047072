import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
const mainScreenSlider = () => {
    const section = document.querySelector('.main-screen');
    const swiper = section === null || section === void 0 ? void 0 : section.querySelector('.swiper');
    const swiperWrapper = swiper === null || swiper === void 0 ? void 0 : swiper.querySelector('.swiper-wrapper');
    if (!section || !swiper || (swiperWrapper === null || swiperWrapper === void 0 ? void 0 : swiperWrapper.children.length) == 1)
        return;
    const desktopPagination = {
        el: section.querySelector('.pagination'),
        type: 'fraction',
        renderFraction(currentClass, totalClass) {
            return `<span class="${currentClass}"></span>/<span class="${totalClass}"></span>`;
        },
        formatFractionCurrent(current) {
            return current < 10 ? `0${current}` : current;
        },
        formatFractionTotal(current) {
            return current < 10 ? `0${current}` : current;
        }
    };
    const mobilePagination = {
        el: section.querySelector('.progressbar'),
        type: 'progressbar',
        progressbarFillClass: 'fill'
    };
    const initSwiper = () => {
        const isMobile = matchMedia('(max-width: 1279px)').matches;
        const swiperInstance = new Swiper(swiper, {
            speed: 500,
            modules: [Navigation, Pagination, Autoplay],
            navigation: {
                prevEl: section.querySelector('.slider-arrow.prev'),
                nextEl: section.querySelector('.slider-arrow.next'),
            },
            autoplay: isMobile ? {
                delay: 56000,
                disableOnInteraction: false
            } : false,
            pagination: isMobile ? mobilePagination : desktopPagination,
            breakpoints: {
                1280: {
                    speed: 900
                }
            }
        });
    };
    initSwiper();
};
mainScreenSlider();
