var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ky from 'ky';
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
export let CountInput = class CountInput extends LitElement {
    constructor() {
        super(...arguments);
        this.src = '';
        this.removeColors = false;
    }
    connectedCallback() {
        super.connectedCallback();
        this.createIcon();
    }
    createIcon() {
        return __awaiter(this, void 0, void 0, function* () {
            const html = yield this.fetchSvg();
            const parser = new DOMParser();
            const result = parser.parseFromString(html, 'text/xml');
            const svg = result.children[0];
            svg.removeAttribute('xmlns:a');
            svg.removeAttribute('xmlns');
            svg.removeAttribute('width');
            svg.removeAttribute('height');
            svg.removeAttribute('x');
            svg.removeAttribute('y');
            svg.removeAttribute('enable-background');
            svg.removeAttribute('xmlns:xlink');
            svg.removeAttribute('xml:space');
            svg.removeAttribute('version');
            if (this.removeColors) {
                const coloredElements = svg.querySelectorAll('[fill], [stroke]');
                coloredElements.forEach(element => {
                    element.removeAttribute('fill');
                    element.removeAttribute('stroke');
                });
            }
            this.icon = svg;
        });
    }
    fetchSvg() {
        return ky.get(this.src).text();
    }
    render() {
        return html `
    ${this.icon}
    `;
    }
};
CountInput.styles = css `
    :host{
      display: block;
    }
    svg{
      width: var(--icon-width);
      height: var(--icon-height);
      fill: var(--icon-fill);
      stroke: var(--icon-stroke);
      transition-property: stroke, fill;
      transition-duration: var(--transition-time);
    }
  `;
__decorate([
    property()
], CountInput.prototype, "src", void 0);
__decorate([
    property()
], CountInput.prototype, "icon", void 0);
__decorate([
    property()
], CountInput.prototype, "removeColors", void 0);
CountInput = __decorate([
    customElement('inline-svg')
], CountInput);
