//переписать на ts

function initEffect(){
  const cards = document.querySelectorAll(".service");
  if (!cards.length)
    return

  

  const applyEffect = (card, direction, effect) => {
    card.className = card.dataset.className

    card.classList.add(`${effect}-${direction}`)
  }

  cards.forEach(card => {
    card.addEventListener('mouseenter', (event) => {
      const direction = determineDirection(card, {x: event.clientX, y: event.clientY})
      applyEffect(card, direction, 'in')
    })
    card.addEventListener('mouseleave', (event) => {
      const direction = determineDirection(card, {x: event.clientX, y: event.clientY})
      applyEffect(card, direction, 'out')
    })
  })
}
initEffect()

function determineDirection(element, pos){
  const directions = ['top', 'right', 'bottom', 'left']
  const offset = element.getBoundingClientRect()
  const w = element.clientWidth
  const h = element.clientHeight
  const x = (pos.x - offset.x - (w/2)) * (w > h ? (h/w) : 1)
  const y = (pos.y - offset.y  - (h/2)) * (h > w ? (w/h) : 1)
  
  const result = Math.round((((Math.atan2(y,x) * (180/Math.PI)) + 180)) / 90 + 3) % 4;
  
  return directions[result]
}