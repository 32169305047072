import Swiper, { Navigation } from 'swiper';
const employeesList = () => {
    if (!matchMedia('(max-width: 1279px)').matches)
        return;
    const section = document.querySelector('.employees-list');
    if (!section)
        return;
    const initSwiper = (slider) => {
        new Swiper(slider, {
            modules: [Navigation],
            slidesPerView: 1,
            navigation: {
                prevEl: slider.querySelector('.prev'),
                nextEl: slider.querySelector('.next')
            },
            breakpoints: {
                576: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                }
            }
        });
    };
    const sliders = section.querySelectorAll('.swiper');
    sliders.forEach(initSwiper);
};
employeesList();
